import {createStore} from 'vuex'

export default createStore({
    state: {
        brandConfig: undefined,
        campaignId: 0,
        googleClickId: undefined,
        evinaToken: undefined,
        encryptedMsisdn: undefined,
        token: undefined,
        msisdn: undefined,
        serviceId: undefined,
        clickId: undefined,
        contentBlockedEnabled: undefined,
        isLoading: false,
        contentBlockOverrideAccepted: false,
        user: undefined,
        scheme: 'daily',
        theme: 1,
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setBrandConfig(state, brandConfig) {
            state.brandConfig = brandConfig
        },
        setCampaignId(state, campaignId) {
            state.campaignId = campaignId
        },
        setGoogleClickId(state, googleClickId) {
            state.googleClickId = googleClickId
        },
        setEvinaToken(state, evinaToken) {
            state.evinaToken = evinaToken
        },
        setToken(state, token) {
            state.token = token
        },
        setMsisdn(state, msisdn) {
            state.msisdn = msisdn
        },
        setEncryptedMsisdn(state, msisdn) {
            state.encryptedMsisdn = msisdn
        },
        setServiceId(state, serviceId) {
            state.serviceId = serviceId
        },
        setClickId(state, clickId) {
            state.clickId = clickId
        },
        setContentBlockedEnabled(state, contentBlockedStatus) {
            state.contentBlockedEnabled = contentBlockedStatus
        },
        setContentBlockOverrideAccepted(state, contentBlockOverrideAccepted) {
            state.contentBlockOverrideAccepted = contentBlockOverrideAccepted
        },
        setUser(state, user) {
            state.user = user
        },
        setTheme(state, theme) {
            state.theme = theme
        },
        setScheme(state, scheme) {
            state.scheme = scheme
        }
    },
    actions: {},
    modules: {}
})
