import {createRouter, createWebHistory} from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";
import {brandConfigMap} from "@/brand";
import {getBrandConfigFromGameParameter} from "@/functions/setBrandConfig";

const routes = [
    {
        path: '/',
        redirect: getBaseRoute(),
    },
    {
        path: '/dcb/opt-in-confirmation',
        name: 'DoiTwoDcb',
        component: () => import("../views/pages/internalEvina/DoiTwo"),
    },
    {
        path: '/upstream/opt-in-confirmation',
        name: 'DoiTwoUpstream',
        component: () => import("../views/pages/upstreamEvina/DoiTwo"),
    },
    {
        path: '/dcb/evina/opt-in-confirmation',
        name: 'DoiTwoVodacomEvina',
        component: () => import("../views/pages/vodacomEvina/DoiTwo"),
    },
    {
        path: '/dcb/evina/content-opt-in',
        name: 'ContentBlockOverride',
        component: () => import("../views/pages/vodacomEvina/ContentBlockOptIn"),
    },
    {
        path: '/dcb/wifi',
        name: 'OnWifiInternalEvina',
        component: () => import("../views/pages/internalEvina/OnWifi"),
    },
    {
        path: '/dcb/evina/wifi',
        name: 'OnWifiVodacomEvina',
        component: () => import("../views/pages/vodacomEvina/OnWifi"),
    },
    {
        path: '/upstream/wifi',
        name: 'OnWifiUpstream',
        component: () => import("../views/pages/upstreamEvina/OnWifi"),
    },
    {
        path: '/generic-error',
        name: 'GenericError',
        component: () => import("../views/pages/errors/GenericError"),
    },
    {
        path: '/evina/generic-error',
        name: 'GenericEvinaError',
        component: () => import("../views/pages/vodacomEvina/errors/GenericError"),
    },
    {
        path: '/content-block-error',
        name: 'ContentBlock',
        component: () => import("../views/pages/vodacomEvina/errors/ContentBlock.vue"),
    },
    {
        path: '/invalid-user-error',
        name: 'InvalidUser',
        component: () => import("../views/pages/vodacomEvina/errors/InvalidUser.vue"),
    },
    {
        path: '/evina/insufficient-funds-error',
        name: 'EvinaInsufficientFunds',
        component: () => import("../views/pages/vodacomEvina/errors/InsufficientFunds.vue"),
    },
    {
        path: '/timeout-error',
        name: 'TimeoutError',
        component: () => import("../views/pages/vodacomEvina/errors/TimeoutError.vue"),
    },
    {
        path: '/insufficient-funds-error',
        name: 'InsufficientFunds',
        component: () => import("../views/pages/errors/InsufficientFunds.vue"),
    },
    {
        path: '/choose-package',
        name: 'ChoosePackage',
        component: () => import("../views/pages/vodacomEvina/ChoosePackage.vue"),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    }
})

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
})

function getBaseRoute() {
    const domain = window.origin;
    const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : getBrandConfigFromGameParameter();
    return brandConfig.baseRoute;
}

export default router
